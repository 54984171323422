.legal-notices-container {
    margin: 70px 95px 30px;
    padding: 10px 40px;
    /*box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);*/
}

ol {
    /*padding-inline-start: 25px;*/
    padding-left: 0;
    list-style: inside decimal;
}

li::marker {
    font-size: 1.5em;
    font-weight: bold;
}

li > h2 {
    display: inline-block;
    margin: 1em 0 0 0;
}

.footer-bottom-legal-notices {
    background-color: #000000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 235px;
    /*gap: 35rem;*/
}

@media only screen and (max-width: 1180px) {
    .footer-bottom-legal-notices {
        padding: 0 25px;
    }
}

@media screen and (max-width: 768px) {
    .legal-notices-container {
        margin: 0 30px 30px;
    }

    .footer-bottom-legal-notices {
        padding: 0 8px;
        gap: 0;
    }
}