html, body {
    /*width: auto !important;*/
    /*overflow-x: hidden !important;*/
    margin: 0;
    max-width: 100%;
    max-height: 100%;
    overflow-x: clip;
    background-color: #F7F7F9;
    font-family: Avenir !important;
}

.App {
    /*width: 100%;*/
    height: 100vh;
    position: relative;
}


