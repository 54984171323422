@font-face {
    font-family: 'Koblenz Serial';
    src: url('../../assets/fonts/KoblenzSerial-ExtraLight.otf') format('opentype');
}

/* GOOGLE RECAPTCHA HIDE */
.grecaptcha-badge {
    opacity: 0;
}

.from-sent-container {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.from-sent-text {
    font-family: 'Avenir';
    font-weight: bold;
    font-size: 18px;
    color: white;
    background: #F29400;
    padding: 40px;
}

.error-text {
    font-size: smaller;
    color: white;
}

.container {
    position: relative;
    width: 100%;
    background-color: #F7F7F9;
}

.container-fluid {
    padding: 0 135px;
}

.navbar-fixed {
    /*width: 100%;*/
    z-index: 2;
    position: fixed;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
    bottom: 0;
    left: 25%;
    right: 25%;
}

.navbar-fixed-container {
    display: flex;
    flex-direction: row;
    filter: drop-shadow(0px 16px 43px rgba(0, 61, 81, 0.17));
    background: #FFFFFF;
}

.navbar-fixed h5 {
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    background: linear-gradient(263.3deg, #FFB300 0%, #F29400 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /*background-clip: text;*/
    /*text-fill-color: transparent;*/
    padding: 24px;
    margin: 0;
    cursor: pointer;
}

.navbar-fixed h5:nth-child(1),
.navbar-fixed h5:nth-child(2) {
    border-right: 1px solid #EAEAED;
}

.navbar {
    position: fixed;
    top: 0;
    z-index: 2;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.navbar-logo-container {
    padding: 26px 135px;
}

.navbar-logo-container > img {
    cursor: pointer;
}

.navbar-flag-container {
    filter: drop-shadow(0px 3.13725px 8.62745px rgba(0, 61, 81, 0.11));
    display: flex;
    justify-content: flex-end;
    padding: 0 135px;
}

.flag-container {
    background-color: #FFFFFF;
    height: 40px;
    transition: all 0.3s linear;
    width: fit-content;
    justify-content: center;
}

.flag-container:hover {
    background: linear-gradient(263.3deg, #FFB300 0%, #F29400 100%);
}

.flag {
    padding: 20px;
    cursor: default;
    outline: none;
    border: none;
    background-color: #F29400;
    -webkit-mask-image: url("../../assets/svg/flag.svg");
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position: center;
    mask-image: url("../../assets/svg/flag.svg");
    mask-repeat: no-repeat;
    mask-position: center;
    transition: all 0.3s linear;
}

.flag.hovered {
    background-color: #ffffff;
}

.flag-languages {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.flag-language {
    cursor: default;
    outline: none;
    border: none;
    background-color: #FFFFFF;
    transition: all 0.3s linear;
    padding: 10px 0;
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 11px;
    color: #F29400;
    opacity: 0;
}

.flag-language.selected {
    background-color: #F29400;
    color: #FFFFFF;
}

.flag-languages.displayed,
.flag-language.displayed {
    opacity: 1;
}

.flag-languages.invisible,
.flag-language.invisible {
    visibility: hidden;
}

.flag-language:hover {
    color: #FFFFFF;
    background-color: #F29400;
}

.background-books {
    background-image: url("../../assets/img/background-books.jpg");
    background-image: -webkit-image-set(url("../../assets/webp/background-books.webp") 1x);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: auto;
    height: 100vh;
    position: relative;
}

.quote-container {
    position: absolute;
    left: 135px;
    top: 65vh;
}

.quote-text {
    width: 369px;
    /*height: 78.29px;*/
    font-style: italic;
    font-weight: 300;
    font-size: 20px;
    line-height: 26px;
    color: #000000;
}

.quote-author {
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
}

.for-who {
    padding: 0 235px;
    margin-bottom: 40px;
}

.for-who-title {
    text-align: center;
    font-style: normal;
    font-weight: 300;
    font-size: 44px;
    line-height: 54px;
    text-transform: uppercase;
    color: #F29400;
}

.for-who-text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    /*padding: 16px 135px;*/
    margin: auto;
    max-width: 770px;
}

.for-who-sectors {
    padding: 0 235px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    column-gap: 10px;
}

.for-who-sectors img {
    width: 170px;
    height: 206px;
    object-fit: cover;
}

.for-who-sectors hr {
    border-top: 1px solid #FFB300;
}

.for-who-sectors h2 {
    font-family: 'Koblenz Serial';
    font-style: normal;
    font-weight: 400;
    font-size: 31px;
    line-height: 48px;
    color: #000000;
    padding-left: 30px;
    padding-right: 30px;
    margin: 0;
}

.for-who-sectors p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    padding-left: 30px;
    padding-right: 30px;
}

.sector-company {
    padding: 34px 0;
    display: flex;
    flex-direction: row-reverse;
    width: 400px;
    visibility: hidden;
}

.sector-press-agency {
    padding: 162px 0 0 0;
    display: flex;
    flex-direction: row;
    width: 570px;
    visibility: hidden;
}

.sector-organization {
    padding: 34px 0;
    display: flex;
    flex-direction: row;
    width: 570px;
    visibility: hidden;
}

.sector-legal {
    padding: 162px 0 0 0;
    display: flex;
    flex-direction: row-reverse;
    width: 470px;
    visibility: hidden;
}

.sector-medical {
    padding: 34px 0;
    display: flex;
    flex-direction: row-reverse;
    width: 470px;
    visibility: hidden;
}

.sector-text-container {
    width: 100%;
    margin-top: 30px;
}

.background-holding-books {
    background-image: url("../../assets/img/background-holding-books.png");
    background-image: -webkit-image-set(url("../../assets/webp/background-holding-books.webp") 1x);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: auto;
    height: 564px;
    z-index: 1;
    position: relative;
    -webkit-clip-path: polygon(0 40%, 100% 0, 100% 60%, 0% 100%);
    clip-path: polygon(0 40%, 100% 0, 100% 60%, 0% 100%);
}

.background-holding-books > div {
    position: absolute;
    right: 20vw;
    top: 10vw;
}

.join-us-text {
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
}

.stop-waiting-text {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 300;
    font-size: 44px;
    line-height: 54px;
    text-transform: uppercase;
    color: #FFFFFF;
    margin: 0 0 18px 0;
}

input {
    font-family: 'Avenir';
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 16px;
    gap: 10px;
    max-width: 454px;
    background: #FFFFFF;
    border: none;
    outline: none;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 1px;
    /*text-transform: uppercase;*/
}

input::placeholder,
textarea::placeholder {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #D9D9DC;
    transition: color 0.2s linear;
}


textarea {
    font-family: 'Avenir';
    padding: 16px;
    gap: 10px;
    max-width: 454px;
    height: 175px;
    background: #FFFFFF;
    border: none;
    resize: none;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 1px;
    /*text-transform: uppercase;*/
    transition: color 0.2s linear;
}


input:hover::placeholder,
textarea:hover::placeholder {
    color: #F29400;
}

.button-container {
    display: flex;
    width: fit-content;
    cursor: pointer;
    height: 48px;
    position: relative;
    justify-content: center;
    margin-top: 20px;
}

.button-background:hover {
    height: 100%;
}

.button {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #FFFFFF;
    padding: 12px 12px;
    text-align: center;
    /*background: linear-gradient(#000000 0 0) */
    /*        left /100% var(--d, 30%) no-repeat;*/
    background: linear-gradient(#000000 0%, #000000 100%)
            left /100% var(--d, 30%) no-repeat;
    background-position: left 100% bottom 30%;
    transition: 0.3s;
    border-width: 0;
    cursor: pointer;
}

.button:hover {
    --d: 100%;
    color: #fff;
}

.col-3 {
    width: 25%;
}

.col-4 {
    width: 33.33%;
}

/*.clients-logos-container {*/
/*    width: 50%;*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    flex-wrap: wrap;*/
/*    align-items: center;*/
/*    !*column-gap: 91px;*!*/
/*    row-gap: 30px;*/
/*    justify-content: space-around;*/
/*    max-width: 511px;*/
/*}*/

.clients-logos-best-translation-container {
    display: flex;
    flex-direction: row;
    padding: 235px 235px 50px 235px;
    background-color: #F7F7F9;
    z-index: 1;
    /*column-gap: 90px;*/
}

.clients-logos-best-translation-container > * + * {
    margin-left: 90px;
}

.clients-logos-container {
    max-width: 50%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    column-gap: 55px;
    row-gap: 20px;
    justify-content: space-evenly;
    /*margin-top: auto;*/
}

.clients-logos-container > div {
    display: flex;
    flex-basis: calc(20% - 14px);
    justify-content: center;
    flex-direction: column;
}

.clients-logos-container img {
    object-fit: contain;
    max-height: 70px;
    margin: 10px 0 10px 10px;
}

.best-translation-possible-container {
    width: 50%;
    align-items: flex-start;
    margin-top: -13rem;
}

.best-translation-possible-title {
    font-style: normal;
    font-weight: 300;
    font-size: 44px;
    line-height: 54px;
    text-transform: uppercase;
    color: #F29400;
    max-width: 570px;
}

.best-translation-possible-text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
}

.footer {
    background: linear-gradient(263.3deg, #FFB300 0%, #F29400 100%);
    /*display: flex;*/
    /*justify-content: space-between;*/
    padding: 100px 235px;
}

.footer-container {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
}

.footer-container > div {
    flex: 1;
}

.contact-us-left-section {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.blurred {
    filter: blur(3px);
}

.contact-us-text {
    font-style: normal;
    font-weight: 300;
    font-size: 44px;
    line-height: 54px;
    text-transform: uppercase;
    color: #FFFFFF;
}

.button-disabled {
    opacity: 0.5;
}

.contact-us-right-section {
    display: flex;
    flex-direction: column;
    width: 600px;
    /*justify-content: space-evenly;*/
    gap: 65px;
}

.company-infos {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    background: #F29400;
    min-width: 165px;
    /*margin-bottom: 48px;*/
}

.company-infos-title {
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 26px;
    color: #FFFFFF;
    padding: 0;
    margin: 0;
}

.company-number {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    padding: 0;
    margin: 0 0 24px 0;
}

.company-infos-text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    column-gap: 40px;
}

.company-infos-text > div {
    display: flex;
    flex-direction: column;
}

.company-infos-phone {
    color: unset;
    text-decoration: none;
}

.mail {
    text-decoration: none;
    color: unset;
}

.freelance-wrapper > div {
    margin-top: 38px;
}

.freelance-wrapper .hr {
    border-top: 1px solid #DA8500;
}

.freelance-img {
    width: 171px;
    height: 238px;
    object-fit: cover;
}

.freelance-text-wrapper {
    display: flex;
    flex-direction: column;
    padding-left: 28px;
}

.freelance-title {
    font-family: 'Koblenz Serial';
    font-style: normal;
    font-weight: 400;
    font-size: 31px;
    line-height: 48px;
    color: #FFFFFF;
    margin: 16px 0;
    padding: 0;
}

.freelance-text {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    padding: 0;
    margin: 0;
}

.footer-bottom {
    background-color: #000000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 235px;
    /*gap: 35rem;*/
}

.legal-notices {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 1px;
    color: #FFFFFF;
    padding: 8px;
    cursor: pointer;
    width: fit-content;
    z-index: 2;
    text-decoration: none;
}

.position-relative {
    position: relative;
}

.scroll-to-anchor {
    position: absolute;
    left: 0;
}

/* Animations */
.animate {
    animation-duration: 1s;
    animation-delay: 0.5s;
    animation-name: animate-fade;
    animation-timing-function: cubic-bezier(.26,.53,.74,1.48);
    animation-fill-mode: backwards;
    visibility: visible;
}

/* Fade In */
.animate.fade {
    animation-name: animate-fade;
    animation-timing-function: ease;
}
@keyframes animate-fade {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

/* Pop In */
.animate.pop {
    animation-name: animate-pop;
}
@keyframes animate-pop {
    0% {
        opacity: 0;
        transform: scale(0.5, 0.5);
    }
    100% {
        opacity: 1;
        transform: scale(1, 1);
    }
}

/* Slide In */
.animate.slide-left { animation-name: animate-slide-left; }
@keyframes animate-slide-left {
    0% {
        opacity: 0;
        transform: translateX(-200px);
    }
    100% {
        opacity: 1;
        transform: translate(0,0);
    }
}
.animate.slide-right { animation-name: animate-slide-right; }
@keyframes animate-slide-right {
    0% {
        opacity: 0;
        transform: translateX(200px);
    }
    100% {
        opacity: 1;
        transform: translate(0,0);
    }
}

/* Animation Delays */
.delay-0 {
    animation-delay: 0.6s;
}
.delay-1 {
    animation-delay: 0.7s;
}
.delay-2 {
    animation-delay: 0.8s;
}
.delay-3 {
    animation-delay: 0.9s;
}
.delay-4 {
    animation-delay: 1s;
}
.delay-5 {
    animation-delay: 1.1s;
}
.delay-6 {
    animation-delay: 1.2s;
}
.delay-7 {
    animation-delay: 1.3s;
}
.delay-8 {
    animation-delay: 1.4s;
}
.delay-9 {
    animation-delay: 1.5s;
}

/* MDS FOOTER */
.copyright-container {
    position: relative;
    /*display: inline-block;*/
    display: flex;
    align-items: center;
    text-decoration: none;
    z-index: 2;
}

.copyright,
.copyright-colored {
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s;
    color: #FFFFFF;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 1px;
    padding: 8px;
    cursor: pointer;
    width: fit-content;
}

.copyright-code, .copyright-heart {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.copyright svg, .copyright-colored svg {
    transform: translateY(12.5%);
}

.copyright svg path, .copyright-colored svg path {
    fill: #FFFFFF;
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s;
}

.copyright span, .copyright-colored span {
    fill: #FFFFFF;
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s;
}

.copyright-colored {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    transform: rotateX(90deg) translateZ(9px);
}

.copyright-colored path, .copyright-code path {
    fill: #939393;
}

.copyright-colored span {
    color: #fa6938;
}

.copyright-colored .copyright-heart path {
    fill: #cb1b74;
}

a:focus .copyright, a:hover .copyright {
    transform: rotateX(-90deg) translateZ(9px);
}

a:focus .copyright-colored, a:hover .copyright-colored {
    transform: rotateX(0) translateZ(9px);
}


@media screen and (prefers-reduced-motion: reduce) {
    .animate {
        animation: none !important;
    }
}

@media only screen and (max-width: 1580px) {
    .cover-text-svg {
        left: calc(37% - 80px);
    }

    .background-holding-books > div {
        top: calc(100% - 24em);
        right: 20vw;
    }

    .for-who,
    .for-who-sectors {
        padding: 0 135px;
    }

    .clients-logos-best-translation-container {
        padding: 235px 135px 50px 135px;
    }

    .footer {
        padding: 100px 135px;
    }
    
    /*.contact-us-right-section {*/
    /*    flex-direction: row;*/
    /*    max-width: 100%;*/
    /*}*/

    .footer-bottom {
        padding: 0 135px;
    }
}

@media only screen and (max-width: 1250px) {
    .quote-container {
        top: 80vh;
    }

    .clients-logos-container {
        column-gap: 20px;
        justify-content: space-around;
        max-width: unset;
    }

    .clients-logos-best-translation-container {
        flex-direction: column-reverse;
        padding-top: 0;
        padding-bottom: 0;
    }

    .clients-logos-best-translation-container > * + * {
        margin-left: 0;
        margin-bottom: 40px;
    }

    .best-translation-possible-container {
        margin-top: 0;
        width: unset;
    }

    .best-translation-possible-title {
        max-width: unset;
        padding: 0;
    }

    .footer-container > div {
        width: 100%;
    }

    .contact-us-right-section {
        /*flex-direction: row-reverse;*/
        width: auto;
        gap: 35px;
    }

    .company-infos-text {
        flex-direction: column;
        gap: 16px;
    }
}

@media only screen and (max-width: 1180px) {
    .background-books {
        margin-top: -15rem;
    }

    .quote-container {
        top: 78vh;
    }

    .for-who-title {
        margin-top: -10px;
    }

    .footer-bottom {
        padding: 0 25px;
    }

    .copyright,
    .copyright-colored {
        width: 13em;
    }
}

@media only screen and (max-width: 992px) {
    .footer-container {
        flex-direction: column;
    }

    input, textarea {
        max-width: unset;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1250px) {
    .for-who-sectors {
        flex-direction: column;
        row-gap: 40px;
    }

    .for-who-sectors > div {
        width: auto;
        padding: 0;
        flex-direction: row-reverse;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1370px) {
    .for-who {
        padding: 0 135px;
    }

    .for-who-sectors {
        padding: 0 135px 30px;
    }

    .background-holding-books > div {
        top: calc(100% - 24em);
        right: 14vw;
    }
}

@media screen and (max-width: 768px) {
    .navbar-logo-container {
        padding: 10px;
    }

    .navbar-flag-container {
        padding: 10px;
        justify-content: flex-end;
    }

    .cover-text-svg {
        width: 220px;
        left: calc(40% - 80px);
        top: calc(48% - 70px);
    }

    .background-books-container {
        height: 100%;
    }

    .background-books {
        margin-right: -2rem;
        zoom: 1.3;
        width: 100%;
    }

    .quote-container {
        left: 0;
        width: 100%;
        zoom: 0.7;
        top: 80%;
    }

    .quote-text {
        width: auto;
        text-align: center;
    }

    .quote-author {
        text-align: center;
    }

    .for-who {
        padding: 40px 20px;
    }

    .for-who-text {
        padding: 0;
    }

    .for-who-sectors {
        padding: 0 0 30px 0;
    }

    .for-who-sectors > div:nth-child(5) {
        margin-left: 0;
    }

    .for-who-sectors h2 {
        font-size: 26px;
        line-height: 30px;
    }

    .for-who-sectors h2,
    .for-who-sectors p {
        padding: 0;
        margin-bottom: 0;
    }

    .sector-organization{
        flex-direction: row-reverse;
    }

    .sector-legal {
        flex-direction: row;
    }

    .sector-press-agency .sector-text-container,
    .sector-legal .sector-text-container {
        padding: 0 0 0 30px;
    }

    .sector-company img,
    .sector-press-agency img,
    .sector-organization img,
    .sector-legal img,
    .sector-medical img {
        height: 280px;
    }

    .sector-text-container {
        margin-top: 0;
        padding: 0 30px 0 0;
    }

    .sector-text-container hr {
        display: none;
    }

    .background-holding-books {
        clip-path: polygon(0 20%, 100% 0, 100% 80%, 0 100%);
        height: 380px;
        background-position-x: right;
    }

    .background-holding-books > div {
        display: flex;
        flex-direction: column;
        padding: 100px 30px;
        justify-content: center;
        align-items: center;
        text-align: center;
        position: unset;
        right: 0;
        top: 0;
    }

    .join-us-text {
        width: 249px;
    }

    .stop-waiting-text {
        font-family: 'Avenir';
        font-style: normal;
        font-weight: 300;
        font-size: 34px;
        line-height: 44px;
        text-align: center;
        text-transform: uppercase;
        color: #FFFFFF;
    }

    .button-container {
        /*width: auto;*/
        margin-top: 0;
    }

    .clients-logos-best-translation-container {
        display: flex;
        flex-direction: column-reverse;
        height: auto;
        padding: 16px 16px;
        gap: 48px;
        margin-bottom: 40px;
        margin-top: -40px;
    }

    .clients-logos-best-translation-container > * + * {
        margin-left: 0;
    }

    .best-translation-possible-container {
        margin-top: 0;
        width: auto;
        z-index: 1;
    }

    .best-translation-possible-title {
        font-size: 34px;
        line-height: 44px;
        text-align: center;
        text-transform: uppercase;
        color: #F29400;
        max-width: 100%;
        position: unset;
        padding: 0;
    }

    .clients-logos-container {
        max-width: 100%;
        column-gap: 22px;
        row-gap: 22px;
    }

    .clients-logos-container img {
        /*max-width: 100px;*/
        max-height: 60px;
        margin: 0;
    }

    .footer {
        padding: 80px 16px 50px;
    }

    .footer-container {
        flex-direction: column;
    }

    .stop-waiting-text {
        margin-bottom: 20px;
    }

    .contact-us-left-section {
        width: 100%;
        justify-content: left;
        margin-bottom: 40px;
    }

    .contact-us-left-section .button-container {
        justify-content: flex-start;
    }

    input,
    textarea {
        max-width: unset;
    }

    .contact-us-right-section {
        width: auto;
        gap: 10px;
        flex-direction: column;
    }

    .company-infos {
        margin-bottom: 40px;
    }

    .company-infos-text {
        flex-direction: column;
        gap: 16px;
    }

    .freelance-wrapper > div {
        margin-top: 0;
    }

    .freelance-wrapper .hr {
        border-top: none;
    }

    .freelance-title {
        margin: 0 0 8px 0;
    }

    .freelance-img {
        height: auto;
        margin-left: -20px;
    }

    .navbar-fixed {
        left: 0;
        right: 0;
        width: auto;
        display: block;
    }

    .navbar-fixed-container {
        justify-content: center;
    }

    .footer-bottom {
        padding: 0 8px 4.2rem;
        gap: 0;
    }

    .copyright,
    .copyright-colored {
        font-size: 10px;
        width: fit-content;
    }

    .copyright > svg,
    .copyright-colored > svg {
        height: 10px;
    }



    .legal-notices {
        justify-content: center;
        margin: 0;
        width: auto;
        text-align: center;
    }
}

@media screen and (max-width: 580px) {
    .background-books {
        /*background-position: -485px 0;*/
        background-position: center 40px;
    }
}

@media screen and (min-width: 414px) and (max-width: 768px) {
    .sector-company,
    .sector-press-agency,
    .sector-organization,
    .sector-legal,
    .sector-medical {
        padding: 34px 20px 0;
        width: 100%;
        justify-content: space-between;
    }

    .sector-company img,
    .sector-press-agency img,
    .sector-organization img,
    .sector-legal img,
    .sector-medical img {
        width: 340px;
        max-width: 100%;
    }

    .sector-text-container {
        width: 308px;
    }
}

@media screen and (max-width: 413px) {
    .sector-company,
    .sector-press-agency,
    .sector-organization,
    .sector-legal,
    .sector-medical {
        padding: 34px 0 0;
        width: 100%;
    }
}